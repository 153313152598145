import "../scss/bs-page.scss";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tab";
import ScrollSpy from "bootstrap/js/dist/scrollspy";
import Tooltip from "bootstrap/js/dist/tooltip";
new ScrollSpy(document.body, {
  target: '#sub-navbar'
});
[].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(function (tooltipTriggerEl) {
  new Tooltip(tooltipTriggerEl);
});